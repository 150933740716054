/**
 * 系统消息通知相关的请求
 */
import axios from './http';

// 消息通知列表接口
export const getMessageList = (params) => {
    return axios({
        method: 'get',
        url: '/ParkingLotApi/SystemMessage/GetPageList',
        params
    })
}

// 修改消息已读接口
export const setMessageRead = (params) => {
    return axios({
        method: 'get',
        url: '/ParkingLotApi/SystemMessage/UpdateRead',
        params
    })
}

// 修改消息弹出状态
export const setMessageEject = (data) => {
    return axios({
        method: 'post',
        url: '/ParkingLotApi/SystemMessage/UpdateEject',
        data
    })
}
