<template>
  <!-- 消息通知 -->
  <div class="box-card">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="时间：">
        <el-date-picker v-model="timeduan" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
          :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
          start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="消息类型：">
        <el-select popper-class="my-select" clearable v-model="queryForm.message_type" placeholder="请选择消息类型"
          @clear="queryForm.message_type = null;" @change="onSubmit()">
          <el-option v-for="item in getOptions('MessageType')" :key="item.value" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="消息内容：">
        <el-input v-model="queryForm.message_content" @keyup.enter.native="onSubmit" placeholder="请输入消息内容"
          @input="(e) => (queryForm.message_content = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="消息通知">
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @size-change="sizeChange"
          @current-change="currentChange">
          <template slot-scope="{row}" slot="message_content">
            <span :style="row.is_read ? 'color: #aaa;' : ''">{{ row.message_content }}</span>
          </template>
          <template slot-scope="{row}" slot="message_type">
            <span :style="row.is_read ? 'color: #aaa;' : ''">{{ getWayName('MessageType', row.message_type) }}</span>
          </template>
          <template slot-scope="{row}" slot="create_time">
            <div :style="row.is_read ? 'color: #aaa;' : ''">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="info" icon="yidu" hint="已读" v-if="row.is_read"></lbButton>
            <lbButton type="warning" icon="weidu" hint="未读" @click="fnSetMessageRead(row)" v-else></lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" :fit="true" border stripe>
        <el-table-column prop="message_content" label="消息内容">
          <template slot-scope="scope">
            <span :style="scope.row.is_read ? 'color: #aaa;': ''">{{ scope.row.message_content }}</span>
          </template>
        </el-table-column>
        <el-table-column label="消息类型">
          <template slot-scope="scope">
            <span :style="scope.row.is_read ? 'color: #aaa;': ''">{{ getWayName('MessageType', scope.row.message_type) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="时间">
          <template slot-scope="scope">
            <div :style="scope.row.is_read ? 'color: #aaa;': ''">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <lbButton type="info" icon="yidu" hint="已读" v-if="scope.row.is_read"></lbButton>
            <lbButton type="warning" icon="weidu" hint="未读" @click="fnSetMessageRead(scope.row)" v-else></lbButton>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" >
      </el-pagination>
      </div>
    </le-card>
  </div>
</template>

<script>
import { getMessageList, setMessageRead } from '@/api/messageApi'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      timeduan: '',
      // 查询表单
      queryForm: {
        start_date: '',
        end_date: '',
        message_type: null,
        message_content: '',
        PageSize: 10,
        PageIndex: 1
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '消息内容',
            prop: 'message_content',
            slot: true
          }, {
            label: '消息类型',
            prop: 'message_type',
            slot: true
          }, {
            label: '时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }

    }
  },
  watch: {
    'timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.start_date = newV[0]
        this.queryForm.end_date = newV[1]
      } else {
        this.queryForm.start_date = ''
        this.queryForm.end_date = ''
        this.onSubmit()
      }
    }
  },
  created () {
    console.log('进入')
    this.fnGetMessageList()
  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName'])
  },
  mounted () {
  },
  methods: {
    async fnGetMessageList () {
      const res = await getMessageList({
        start_date: this.queryForm.start_date,
        end_date: this.queryForm.end_date,
        message_type: this.queryForm.message_type ? this.queryForm.message_type : 0,
        message_content: this.queryForm.message_content,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      if (res && res.Code === 200) {
        this.total = res.Data.TotalCount
        this.tableData = res.Data.DataList
      } else {
        this.total = 0
        this.tableData = []
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      console.log(this.queryForm)
      this.fnGetMessageList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetMessageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetMessageList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetMessageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetMessageList()
    // },
    // 设置为已读状态
    async fnSetMessageRead (row) {
      const res = await setMessageRead({
        _id: row._id
      })
      console.log(res)
      if (res && res.Code === 200) {
        this.fnGetMessageList()
        this.$store.dispatch('requestAlarmCount')
      }
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  margin-top: 20px;
}
</style>
